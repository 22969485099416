import React from 'react';
import imageData from './assets/images/datenschutz.png';
import './style.css';
import {Footer} from "./Footer";

export default class Dataschutz extends React.Component {


    render() {
        return (
            <div>
                <div className="section centered welcome">
                    <img src={imageData} alt=""/>

                    <a href="/">&#x21dc; zurück</a>
                </div>

                <div className='content'>
                    <blockquote>
                        <p><em>Letzte Aktualisierung: 24. Mai 2018</em></p>
                    </blockquote>
                    <p>epilepsieTagebuch („et“, „wir“, „uns“ oder „unser/e/er/es“) hilft Dir dabei epileptische Anfälle
                        und Medikamente zu dokumentieren. Epileptische Anfälle können weitere Eigenschaften zugewiesen
                        werden. In einer Übersicht sieht man schnell einen Trend der Anfälle und zusätzlich alle
                        weiteren Ereignisse, wie Befinden und Medikamentenänderungen.</p>
                    <h2>Verantwortlicher</h2>
                    <p>Daniel Kagemann<br/>
                        Rosenstrasse 16<br/>
                        75328 Schömberg<br/>
                        Deutschland </p>
                    <p>07084-9344531<br/>
                        info@hallo-ich-bin-epi.de </p>
                    <h2>Informationen die wir speichern</h2>
                    <p>Diese Datenschutzerklärung klärt Dich über die Art, den Umfang und Zweck der Verarbeitung von
                        personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unserer app EpilepsieTagebuch
                        (nachfolgend als "App" bezeichnet) und der mit ihm verbundenen Webseiten, Funktionen und Inhalte
                        sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend bezeichnet
                        als „Webseiten“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“
                        oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
                        Datenschutzgrundverordnung (DSGVO).</p>
                    <p>Wir möchten Dir in den Datenschutzrichtlinien folgendes erläutern,</p>
                    <ul>
                        <li>welche Daten Du uns selber gibst</li>
                        <li>welche Daten wir von Dir speichern</li>
                        <li>wie wir die Daten nutzen</li>
                        <li>ob und wie wir die Daten an Dritte weitergeben</li>
                        <li>welche Rechte Du als Benutzer hast</li>
                    </ul>
                    <h2>Arten der verarbeiteten Daten</h2>
                    <ul>
                        <li>Bestandsdaten (z.B., Namen, Adressen).</li>
                        <li>Kontaktdaten (z.B., E-Mail).</li>
                        <li>Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
                        <li>Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
                        <li>Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</li>
                    </ul>
                    <h2>Daten von Dir selbst</h2>
                    <p>Alle erfassten Daten der App werden zunächst nur auf Deinem Smartphone gespeichert. Sobald Du
                        unsere Sicherungsfunktion verwenden möchtest, benötigst Du ein Benutzerkonto.</p>
                    <p>Für ein Benutzerkonto erfassen wir lediglich Deine E-Mail-Adresse ("Kontaktdaten"). Die
                        Erstellung des Kontos ist einmalig erforderlich.<br/>
                        Sobald Du ein Benutzerkonto erstellt hast, kannst Du damit beginnen Deine Daten zu sichern und
                        bei Bedarf wiederherzustellen.</p>
                    <p>Die E-Mail-Adresse hilft uns, Dir Nachrichten vom System zu schicken. Diese Nachrichten sind
                        technischer Natur und nicht mit einem Newsletter vergleichbar. Des weiteren hilft sie auch
                        dabei, Dein Benutzerkonto zu reaktivieren, falls Du die Daten mal verloren hast oder die
                        Anmeldung nicht mehr funktioniert.</p>
                    <h2>Daten die wir von Dir sammeln</h2>
                    <p>Alle erfassten Daten ("Inhaltsdaten") werden lediglich bei der Sicherung mit Deinem Benutzerkonto
                        verknüpft.</p>
                    <p>Informationen über Deine Position werden vom Smartphone ausgelesen, sofern Du dem vorab
                        zugestimmt hast. Die zustimmung kann jederzeit in den Smartphoneeinstellungen widerrufen werden.
                        Es erfolgt kein Zugriff auf Deine Kontakte oder sonstige Daten.</p>
                    <h2>Wie werden die Daten verwendet</h2>
                    <p>Die Daten die Du uns bereitstellst werden im System gespeichert. Sie helfen uns, Dich im
                        Fehlerfall eindeutig identifizieren zu können und Dein Konto zu reaktivieren.</p>
                    <p>Bei der Anmeldung bekommst Du zum Beispiel solch eine Willkommens-E-Mail.</p>
                    <h2>Weitergabe an Dritte</h2>
                    <p>Alle Daten die wir in unserem System von Dir speichern, werden zu keinem Zeitpunkt an Dritte
                        weitergegeben.</p>
                    <p>Wir verwenden die Daten nicht für Werbezwecke.</p>
                    <p>Alle Daten können auf Deinen Wunsch unwiderruflich aus unserem System gelöscht werden. Sende uns
                        hierzu einfach eine E-Mail an info@hallo-ich-bin-epi.de.</p>
                    <h2>Analyseinformationen</h2>
                    <p>Um Statistiken zu ermöglichen werden die erfassten Daten analysiert. Hier werden die bereits
                        erfassten Daten lediglich zu Einheiten gruppiert und dargestellt. Es werden nur Deine eigenen
                        Daten bei der Analyse berücksichtigt. </p>
                    <h2>Cookies und ähnliche Technologien</h2>
                    <p>Wir speichern eine Information auf Deinem Smartphone in Dateien, um Deinen Login zu
                        vereinfachen.</p>
                    <h2>Rechte der betroffenen Personen</h2>
                    <p>Du hast jederzeit das Recht auf unentgeltliche Auskunft über Deine gespeicherten
                        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie
                        ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
                        zum Thema personenbezogene Daten kannst Du Dich jederzeit unter der im Impressum angegebenen
                        Adresse an uns wenden.</p>
                    <p>Du hast das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden
                        und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten
                        entsprechend Art. 15 DSGVO.</p>
                    <p>Du hast entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Dich betreffenden Daten
                        oder die Berichtigung der Dich betreffenden unrichtigen Daten zu verlangen.</p>
                    <p>Du hast nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten
                        unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung
                        der Verarbeitung der Daten zu verlangen.</p>
                    <p>Du hast das Recht zu verlangen, dass die Dich betreffenden Daten, die Du uns bereitgestellt haben
                        nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu
                        fordern. </p>
                    <p>Du hast ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
                        einzureichen.</p>
                    <h2>Löschung von Daten</h2>
                    <p>Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in
                        ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung
                        ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für Deine
                        Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen
                        Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für
                        andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
                        D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten,
                        die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.</p>
                    <p>Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre
                        gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen,
                        Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung relevanter Unterlagen, etc.) und 6
                        Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe). </p>
                    <p>Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß §
                        132 Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere,
                        Aufstellung der Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und
                        für 10 Jahre bei Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen,
                        Telekommunikations-, Rundfunk- und Fernsehleistungen, die an Nichtunternehmer in
                        EU-Mitgliedstaaten erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch
                        genommen wird.</p>
                    <h2>Hosting und E-Mail-Versand</h2>
                    <p>Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der
                        folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
                        Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische
                        Wartungsleistungen, die wir zum Zwecke des Betriebs der App und Webseiten einsetzen. </p>
                    <p>Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
                        Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und
                        Besuchern der WEbseiten auf Grundlage unserer berechtigten Interessen an einer effizienten und
                        sicheren Zurverfügungstellung dieses Angebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28
                        DSGVO (Abschluss Auftragsverarbeitungsvertrag).</p>
                    <h2>Erhebung von Zugriffsdaten und Logfiles</h2>
                    <p>Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne
                        des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser
                        Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
                        Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über
                        erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die
                        zuvor besuchte Seite), IP-Adresse und der anfragende Provider.</p>
                    <p>Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
                        Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten,
                        deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung
                        des jeweiligen Vorfalls von der Löschung ausgenommen.</p>
                    <h2>Onlinepräsenzen in sozialen Medien</h2>
                    <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort
                        aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen
                        informieren zu können. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die
                        Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber. </p>
                    <p>Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten
                        der Nutzer sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren,
                        z.B. Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.</p>
                    <h2>Einbindung von Diensten und Inhalten Dritter</h2>
                    <p>Wir setzen innerhalb unserer Webseiten auf Grundlage unserer berechtigten Interessen (d.h.
                        Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unserer Webseiten im Sinne
                        des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren
                        Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich
                        bezeichnet als “Inhalte”). </p>
                    <p>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer
                        wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
                        IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche
                        Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der
                        Inhalte verwenden. Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken,
                        auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die
                        "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Website
                        ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der
                        Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und
                        Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unserer
                        Webseiten enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden
                        werden.</p>
                    <h2>Google Fonts</h2>
                    <p>Wir binden die Schriftarten ("Google Fonts") des Anbieters Google LLC, 1600 Amphitheatre Parkway,
                        Mountain View, CA 94043, USA, ein. Datenschutzerklärung: <a
                            href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>,
                        Opt-Out: <a
                            href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>.
                    </p>
                    <h2>Verwendung von Facebook Social Plugins</h2>
                    <p>Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse,
                        Optimierung und wirtschaftlichem Betrieb unserer Webseiten im Sinne des Art. 6 Abs. 1 lit. f.
                        DSGVO) Social Plugins ("Plugins") des sozialen Netzwerkes facebook.com, welches von der Facebook
                        Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird
                        ("Facebook"). Die Plugins können Interaktionselemente oder Inhalte (z.B. Videos, Grafiken oder
                        Textbeiträge) darstellen und sind an einem der Facebook Logos erkennbar (weißes „f“ auf blauer
                        Kachel, den Begriffen "Like", "Gefällt mir" oder einem „Daumen hoch“-Zeichen) oder sind mit dem
                        Zusatz "Facebook Social Plugin" gekennzeichnet. Die Liste und das Aussehen der Facebook Social
                        Plugins kann hier eingesehen werden: <a
                            href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a>.
                    </p>
                    <p>Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie,
                        das europäische Datenschutzrecht einzuhalten (<a
                            href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>).
                    </p>
                    <p>Wenn ein Nutzer eine Funktion unserer Webseiten aufruft, die ein solches Plugin enthält, baut
                        sein Gerät eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird
                        von Facebook direkt an das Gerät des Nutzers übermittelt und von diesem in der Webseite
                        eingebunden. Dabei können aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt
                        werden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses
                        Plugins erhebt und informiert die Nutzer daher entsprechend unserem Kenntnisstand.</p>
                    <p>Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die
                        entsprechende Seite der WEbseite aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann
                        Facebook den Besuch seinem Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren,
                        zum Beispiel den Like Button betätigen oder einen Kommentar abgeben, wird die entsprechende
                        Information von Ihrem Gerät direkt an Facebook übermittelt und dort gespeichert. Falls ein
                        Nutzer kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook seine
                        IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine
                        anonymisierte IP-Adresse gespeichert.</p>
                    <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch
                        Facebook sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der
                        Privatsphäre der Nutzer, können diese den Datenschutzhinweisen von Facebook entnehmen: <a
                            href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.
                    </p>
                    <p>Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über diese Webseite Daten
                        über ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er
                        sich vor der Nutzung unserer Webseite bei Facebook ausloggen und seine Cookies löschen. Weitere
                        Einstellungen und Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb der
                        Facebook-Profileinstellungen möglich: <a
                            href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a> oder
                        über die US-amerikanische Seite <a
                            href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die
                        EU-Seite <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>. Die
                        Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer
                        oder mobile Geräte übernommen.</p>
                    <h2>Twitter</h2>
                    <p>Innerhalb unserer WEbseiten können Funktionen und Inhalte des Dienstes Twitter, angeboten durch
                        die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA, eingebunden
                        werden. Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit
                        denen Nutzer Inhalte diese Webseite innerhalb von Twitter teilen können.<br/>
                        Sofern die Nutzer Mitglieder der Plattform Twitter sind, kann Twitter den Aufruf der o.g.
                        Inhalte und Funktionen den dortigen Profilen der Nutzer zuordnen. Twitter ist unter dem
                        Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
                        Datenschutzrecht einzuhalten (<a
                            href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a>).
                        Datenschutzerklärung: <a
                            href="https://twitter.com/de/privacy">https://twitter.com/de/privacy</a>, Opt-Out: <a
                            href="https://twitter.com/personalization">https://twitter.com/personalization</a>.</p>
                    <h2>Kontaktaufnahme mit uns</h2>
                    <p>Falls du Fragen zu diesen Datenschutzrichtlinien oder zu epilepsieTagebuch hast, kannst Du uns
                        gerne per E-Mail kontaktieren.</p>
                </div>
                <Footer/>
            </div>
        );
    }
}