import React from 'react';
import imageImpressum from './assets/images/impressum.png';
import './style.css';
import {Footer} from "./Footer";

export default class Impressum extends React.Component {
    render() {
        return (
            <div>
                <div className="section centered welcome">
                    <img src={imageImpressum} alt=""/>

                    <a href="/">&#x21dc; zurück</a>
                </div>

                <div className='content'>
                    <h2>Angaben gemäß § 5 TMG:</h2>
                    <p>Daniel Kagemann<br/>
                        Rosenstrasse 16<br/>
                        75328 Schömberg</p>
                    <h2>Kontakt:</h2>
                    <p>Telefon: 70849344531<br/>
                        E-Mail: info@hallo-ich-bin-epi.de</p>
                    <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
                    <p>Daniel Kagemann </p>
                    <h2>Haftung für Inhalte</h2>
                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                        nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                        Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                        Tätigkeit hinweisen.<br/>
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                        allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                        umgehend entfernen.</p>
                    <h2>Haftung für Links</h2>
                    <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
                        Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                        übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                        Zeitpunkt der Verlinkung nicht erkennbar.<br/>
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                    <h2>Urheberrecht</h2>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
                        dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                        der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                        Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
                        nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br/>
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                        gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                        werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p></div>
                <Footer/>
            </div>
        );
    }
}