import React, {useState} from 'react';
import {Footer} from "./Footer";

export default function Report() {

    const [input, setInput] = useState('');
    const [error, setError] = useState('');
    const [access, setAccess] = useState('email');
    const [data, setData] = useState({year: new Date().getFullYear(), month: new Date().getMonth(), details: 0})

    /**
     * handle email or token send
     */
    const onAccess = () => {
        setError('');
        if (access === 'email') {
            fetch('https://hallo-ich-bin-epi.de/api/v2/user/' + encodeURI(input))
                .then(res => {
                    if (res.ok) {
                        setAccess('token');
                        setInput('');
                    } else {
                        setError('Die Email ist nicht bekannt');
                    }
                });
        } else if (access === 'token') {
            fetch('https://hallo-ich-bin-epi.de/api/v2/user/auth/' + encodeURI(input))
                .then(res => {
                        if (res.ok) {
                            setAccess('access');
                        } else {
                            setError('Ungültiger Token');
                        }
                    }
                );
        } else {
            fetch(`https://hallo-ich-bin-epi.de/api/v2/report/${data.month}/${data.year}/${data.details}`, {
                method: 'GET',
                headers: {token: input}
            })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    setError('Bericht konnte nicht erzeugt werden.');
                    return {};
                })
                .then(body => {
                    if (body.url) {
                        window.open(body.url);
                    }
                })
        }
    };

    if (access !== 'access') {
        return (
            <>
                <div className="pageheader report"/>

                <div className="content centered pbFooter">
                    <div className="pagetitle">Monatsbericht erstellen</div>
                    <a href="/">&#x21dc; zurück</a>

                    {access === 'email' ?
                        <p>Bitte gib Deine Email Adresse ein. Du bekommst dann einen Anmeldetoken zugeschickt.</p>
                        :
                        <p>Bitte gib den Anmeldetoken ein, den Du per EMail bekommen hast</p>
                    }
                    <div className={'form'}>
                        <input type="text" value={input}
                               onChange={(ev) => setInput(ev.target.value)}
                               placeholder={access === 'email' ? 'Email Adresse' : 'Anmeldetoken'}/>
                        {error.length > 0 && <p className={'error'}>{error}</p>}

                        <button onClick={() => onAccess()}>Senden</button>
                    </div>
                </div>
                <Footer static/>
            </>
        );
    }

    const years = Array(3).fill(new Date().getFullYear(), 0).map((year, i) => year - 2 + i);
    const months = 'Januar,Februar,März,April,Mai,Juni,Juli,August,September,Oktober,November,Dezember'.split(',');

    return (
        <>
            <div className="pageheader report"/>

            <div className="content centered pbFooter">
                <div className="pagetitle">Monatsbericht erstellen</div>
                <a href="/">&#x21dc; zurück</a>

                <div className={'form'}>

                    <div className={'mb16'}>
                        <p>Bitte wähle das Jahr. Hier nur die letzten drei Jahre möglich.</p>
                        {years.map(year => <button key={year} className={year === data.year ? 'selected' : ''}
                                                   onClick={() => setData({...data, year})}>{year}</button>)}
                    </div>
                    <div className={'mb16'}>
                        <p>Bitte wähle den Monat. </p>
                        {months.map((month, index) => <button key={month}
                                                              className={index === data.month ? 'selected' : ''}
                                                              onClick={() => setData({
                                                                  ...data,
                                                                  month: index
                                                              })}>{month}</button>)}
                    </div>
                    <div className={'mb16'}>
                        <p>Möchtest Du Detailinformationen zu den Anfällen ? </p>
                        <button className={data.details === 1 ? 'selected' : ''}
                                onClick={() => setData({...data, details: 1})}>Ja
                        </button>
                        <button className={data.details === 0 ? 'selected' : ''}
                                onClick={() => setData({...data, details: 0})}>Nein
                        </button>
                    </div>

                    {error.length > 0 && <p className={'error'}>{error}</p>}

                    <div className="mb16"/>

                    <button onClick={() => onAccess()}>Generieren</button>
                </div>
            </div>
            <Footer static/>
        </>
    );
}
