import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from "./App";
import {HashRouter, Switch, Route} from 'react-router-dom';
import NotFound from "./NotFound";
import Impressum from "./Impressum";
import ScrollToTop from "./ScrollToTop";
import Datenschutz from "./Datenschutz";
import Barcode from "./Barcode";
import EMail from "./EMail";
import Report from "./Report";

ReactDOM.render((
    <HashRouter>
        <ScrollToTop />
        <Switch>
            <Route exact path='/' component={App}/>
            <Route path='/impressum' component={Impressum}/>
            <Route path='/datenschutz' component={Datenschutz}/>
            <Route path='/barcode' component={Barcode}/>
            <Route path='/report' component={Report}/>
            <Route path='/mailing' component={EMail}/>
            <Route component={NotFound}/>
        </Switch>
    </HashRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
