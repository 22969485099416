import React from 'react';
import './style.css';

import helloImage from './assets/images/hello.png';

export const Welcome = () => {
    return (
        <div className="section full centered welcome">
            <img src={helloImage} alt="" />
                <div className="blink" />
                <p>Das epilepsieTagebuch hilft dabei </p>
                <ul>
                    <li>Anfälle zu dokumentieren</li>
                    <li>Medikamente zu verwalten</li>
                    <li>Anfälle zu analysieren</li>
                    <li>Monatsberichte zu erstellen</li>
                </ul>
        </div>
    );
};