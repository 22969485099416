import React from 'react';
import imageNotFound from './assets/images/notfound.png';
import './style.css';

export default class NotFound extends React.Component {

    render() {
        return (
            <div className="section full centered welcome">
                <img src={imageNotFound} alt=""/>
                <div>Das was Du gesucht hast konnte ich nicht finden.</div>
                <div><a href="/">Zurück zur Startseite</a></div>
            </div>
        );
    }
}