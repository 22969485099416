import React from 'react';
import './style.css';
import {thesecret} from "./globals";

export default class EMail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            access: false,
            pass: ''
        };
    }

    check(ev) {
        if (ev.target.value === thesecret) {
            this.setState({pass: '', access: true});
        } else {
            this.setState({pass: ev.target.value});
        }
    }

    render() {
        if (!this.state.access) {
            return (
                <div>
                    <div className="section centered">

                        <div className="mailing-signin">
                            epilepsieTagebuch <strong>Mailing</strong>
                            <a href="/">&#x21dc; zurück</a>
                        </div>
                    </div>

                    <div className='content barcode'>
                        <p>Der Zugang zu diesem Bereich ist geschützt. Versuche Dein Glück :-)</p>

                        <div>
                            <input type="password" value={this.state.pass}
                                   onChange={(ev) => this.check(ev)} placeholder="Dein Tipp ?!"/>
                        </div>
                    </div>
                </div>
            );
        }
    }
}