import React from 'react';
import './style.css';
import {Welcome} from "./Welcome";
import {SectionImage} from "./SectionImage";
import {Footer} from "./Footer";
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.css'

import imageOverview from './assets/images/iphone-overview.png';
import imageSeizure from './assets/images/iphone-seizure.png';
import imageMed from './assets/images/iphone-med.png';
import imageAccount from './assets/images/iphone-account.png';
import imageSpecials from './assets/images/iphone-specials.png';
import imageios from './assets/images/itunes.svg';
import imageandroid from './assets/images/google.png';

function App() {

    const delay = 100;

    return (
        <div>
            <Welcome/>
            <SectionImage image={'chart'}
                          text={'Einfache und benutzerfreundliche Oberfläche bietet alle Informationen auf einen Blick.'}
                          animate={'slideInUp'}/>

            <div className="section brief">
                <ScrollAnimation offset={10} initiallyVisible={true} animateIn={'bounceInLeft'} delay={delay}>
                    <img src={imageOverview} className="h70" alt=""/>
                </ScrollAnimation>
                <div>
                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Durch den Kalender bekommst Du einen schnellen Überblick über die letzten Tage und Wochen.
                            Die Ereignisse werden tagesweise angezeigt. Wähle einen Tag aus und die Ereignisse werden für diesen Tag angezeigt.
                            Gab es einen Medikamentenwechsel ? Dann werden die Unterschiede zu vorherigen Wechsel angezeigt.
                        </p>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Alle Ereignisse werden in einer Zeitlinie angezeigt. Bei Anfällen werden die Zeitabstände
                            zusätzlich dargestellt. Tippe auf ein Ereignis um es zu bearbeiten oder zu löschen.
                        </p>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Mit Hilfe des Charts erkennestn Du einen Trend über die letzten Tage. Rote Markierungen
                            zeigen
                            die schlechteren Tage an. Es kann zwischen Anzahl der Anfälle und Dauer per Touch gewechselt
                            werden.
                        </p>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Zusätzliche gibt es die Möglichkeit, alle Ereignisse des Tages mit allen Informationen in
                            Textform anzuzeigen. Diese Informationen können dann auch mit anderen geteilt werden.
                        </p>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Gab es an einem Tag viele Ereignisse ? Reduziere den Kalender auf die aktuelle Woche um
                            alle Ereignisse besser im Blick zu haben.
                        </p>
                    </ScrollAnimation>
                </div>
            </div>

            <SectionImage image={'map'}
                          text={'Sobald Du einen Anfall dokumentierst, wird der aktuelle Standort angezeigt und mit in die Daten übernommen. Natürlich nur, wenn Du das möchtest.'}
                          animate={'slideInUp'}/>

            <div className="section brief">
                <div>
                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">Jeder epileptische Anfall ist anders. Das kann hier dokumentiert werden. Neben der Art des
                            Anfalls, können auch Dauer und zusätzliche Hinweise angegeben werden.</p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">
                            Wenn Du möchtest, wird der aktuelle Standort ermittelt oder Du kannst ihn selber eintragen. Auch wenn die Erlaubnis erteilt
                            wurde, kannst Du den Standort jederzeit entfernen.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">Definiere eigene Hinweise oder Auslöser und weise diese jedem Anfall gezielt zu.</p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">Wie stark war der Anfall ? Von mild bis sehr stark kann man ihn einstufen.</p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">Leider gibt es auch Tage an denen es viele Anfälle gibt. Gab es Anfälle mit gleichen Merkmalen, kannst Du diese all auf einmal eintragen. Die Zeiten können selber eingestellt werden oder Du überlässt die Verteilung der App.</p>
                    </ScrollAnimation>

                </div>
                <ScrollAnimation animateIn={'bounceInRight'} delay={delay}>
                    <img src={imageSeizure} className="h70" alt=""/>
                </ScrollAnimation>
            </div>

            <SectionImage image={'medication'}
                          text={'Dokumentiere jede Medikamentenänderung. Dosierung und Tageszeit können ganz einfach angegeben werden'}
                          animate={'slideInUp'}/>

            <div className="section brief">
                <ScrollAnimation animateIn={'bounceInLeft'} delay={delay}>
                    <img src={imageMed} className="h70" alt=""/>
                </ScrollAnimation>
                <div>
                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Du kannst Medikamente ganz einfach hinzufügen. Neben dem Namen werden Dosierung und
                            Tageszeit für die Gabe gespeichert.
                            Zusätzlich kannst Du die Tage angeben, an denen das Medikamente verabreicht werden soll.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Sobald Du den Namen des Medikamentes eingibst werden passende Vorschläge
                            angezeigt. Alternativ kannst Du den Barcode-Scanner benutzen.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInRight'}>
                        <p className="description">
                            Erstelle für jede Änderung einen neuen Einnahmeplan. Das hilft um einen genauen Überblick
                            über die Änderungen zu erhalten. Bei mehr als zwei Einnahmeplänen kannst Du Dir eine
                            Zeitlinie anzeigen. Dort sind die durchschnittliche Anzahl Anfälle und Medikamentenänderungen
                            zwischen den Einnahmeplänen aufgelistet.
                        </p>
                    </ScrollAnimation>
                </div>
            </div>

            <SectionImage image={'special'} animate={'slideInUp'}
                          text={'Füge besondere Ereignisse hinzu, wie beispielweise Ernährungsänderung, Ortswechel, Kopfschmerzen, ...'}/>

            <div className="section brief">
                <div>
                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">
                            Jede Änderung, die vom alltäglichen Abweicht, kann Einfluss auf die Anfälle nehmen. Um diese
                            Besonderheiten dokumentieren zu können, gibt es die neue Rubrik.
                            Egal ob Ernährungsänderung, Kopfschmerzen, Ortswechsel oder das Erfassen spezieller Werte.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">
                            Wähle zunächst einen Bereich aus (Kopfschmerzen, Ortswechsel, ...).
                            Danach einfach die entsprechende Eigenschaft wählen und bei Bedarf eine persönliche Notiz.
                            Alle Besonderheiten werden in der Statistik und in der Übersicht angezeigt.
                        </p>
                    </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn={'bounceInRight'} delay={delay}>
                    <img src={imageSpecials} className="h70" alt=""/>
                </ScrollAnimation>

            </div>

            <SectionImage image={'secure'}
                          text={'Persönliche Daten sind sensibel. Deswegen speichern wir alle Daten verschlüsselt auf unserem Server.'}
                          animate={'slideInUp'}/>

            <div className="section brief">
                <div>
                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">
                            Damit Ihre Daten gesichert werden können ist ein Benutzerkonto erforderlich. Hierzu
                            benötigen wir lediglich Deine EMail Adresse.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">
                            Die Daten werden automatisch gesichert. Sollten die lokalen Daten
                            einmal verloren gehen oder Du wechselst das Gerät, können die gesicherten Daten einfach
                            wieder durch erneutes Anmelden hergestellt werden.
                        </p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn={'slideInLeft'}>
                        <p className="description">
                            Möchtest Du das Tagebuch mit dem gleichen Konto auf mehreren Geräten verwenden, dann ist das auch kein Problem.
                            Sobald die Synchronisation eingeschaltet ist, werdne die Geräte automatisch synchronisiert.
                            Egal wo etwas geändert wurde, die Daten bleiben aktuell: auf allen Geräten.
                        </p>
                    </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn={'bounceInRight'} delay={delay}>
                    <img src={imageAccount} className="h70" alt=""/>
                </ScrollAnimation>
            </div>

            <SectionImage image={'open'} animate={'bounceInDown'}>

                <div className="row">
                    <a href="https://itunes.apple.com/de/app/epilepsietagebuch/id1319750149?mt=8">
                        <img className="ios" src={imageios} alt=""/>
                    </a>

                    <a href='https://play.google.com/store/apps/details?id=com.simplyui.epilepsietagebuch&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img className="android" alt='Jetzt bei Google Play' src={imageandroid}/>
                    </a>
                </div>

            </SectionImage>

            <Footer/>
        </div>
    );
}

export default App;
