import React from 'react';
import './style.css';
import {Footer} from "./Footer";
import {thesecret} from "./globals";

export default class Barcode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            access: false,
            pass: '',
            results: [],
            pzn: '',
            name: '',
            amount: '',
            unit: ''
        };
    }

    search() {
        fetch('http://hallo-ich-bin-epi.de/api/v2/medication/search/' + this.state.pzn)
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else throw new Error('Barcode nicht gefunden');
            })
            .then(res => {
                this.setState({results: res['list']});
            })
            .catch(err => {
                this.setState({results: []});
                window.open('http://www.google.com/search?q=pzn%20' + this.state.pzn, '_blank');
            });
    }

    add() {
        if (this.state.pzn.trim().length > 0 &&
            this.state.name.trim().length > 0) {
            const payload = {
                pzn: this.state.pzn,
                name: this.state.name,
                unit: this.state.unit,
                size: this.state.amount
            };
            fetch('http://hallo-ich-bin-epi.de/api/v2/medication/add', {
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(payload),
                method: 'post'
            }).then(res => res.json())
                .then(res => {
                    this.setState({
                        name: '',
                        amount: '',
                        unit: ''
                    });
                }).catch(err => {
                console.log(err);
            });
        }
    }

    results() {
        if (this.state.results.length === 0) {
            return null
        }
        return (
            <div className='results'>
                {
                    this.state.results.map((item, i) => (<p key={'result' + i}>{item.pzn} - {item.name}</p>))
                }
            </div>
        );
    }

    check(ev) {
        if (ev.target.value === thesecret) {
            this.setState({pass: '', access: true});
        } else {
            this.setState({pass: ev.target.value});
        }
    }

    render() {

        if (!this.state.access) {
            return (
                <>
                    <div className="pageheader scan"/>

                    <div className="content centered">
                        <div className="pagetitle">Barcode</div>
                        <a href="/">&#x21dc; zurück</a>

                        <p>Der Zugang zu diesem Bereich ist geschützt.</p>

                        <div className={'form'}>
                            <input type="password" value={this.state.pass}
                                   onChange={(ev) => this.check(ev)} placeholder="Passort"/>
                        </div>
                    </div>
                    <Footer static/>
                </>
            );
        }
        return (
            <div>
                <div className="pageheader scan"/>

                <div className="content centered">
                    <div className="pagetitle">Barcode</div>
                    <a href="/">&#x21dc; zurück</a>


                    <p>Wurde der Barcode nicht gefunden ? Dann kannst Du ihn hier eintragen.</p>

                    <div className={'form'}>
                        <input type="text" value={this.state.pzn}
                               onChange={(ev) => this.setState({pzn: ev.target.value})} placeholder="Barcode"/>
                        <input type="text" value={this.state.name}
                               onChange={(ev) => this.setState({name: ev.target.value})} placeholder="Name"/>
                        <input type="text" value={this.state.amount}
                               onChange={(ev) => this.setState({amount: ev.target.value})} placeholder="Anzahl"/>
                        <input type="text" value={this.state.unit}
                               onChange={(ev) => this.setState({unit: ev.target.value})} placeholder="Einheit"/>

                        <button disabled={this.state.pzn.length === 0 ? 'disabled' : ''}
                                onClick={() => this.search()}>Suchen
                        </button>
                        <button disabled={this.state.pzn.length === 0 ? 'disabled' : ''}
                                onClick={() => this.add()}>Barcode eintragen
                        </button>
                    </div>

                    {this.results()}
                </div>
                <Footer static/>
            </div>
        );
    }
}