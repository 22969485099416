import React from 'react';
import './style.css';
import {Link} from "react-router-dom";

export const Footer = (props) => {
    const year = new Date().getFullYear();

    return (
        <div className={props.static ? "static" : "section"} id="footer">
            <div>Copyright {year}. Daniel Kagemann</div>
            <div>
                <a href="http://paypal.me/DanielKagemann">Unterstützen</a>

                <a href="https://www.facebook.com/epilepsietagebuch">Facebook</a>
                <Link to="/barcode">Barcode</Link>
                <Link to="/report">Berichte</Link>
                {/*<Link to="/mailing">Mailing</Link>*/}
                <Link to="/datenschutz">Datenschutz</Link>
                <Link to="/impressum">Impressum</Link>
                <div>Alle Rechte vorbehalten.</div>
            </div>
        </div>
    );
};