import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.css'
import './style.css';

export class SectionImage extends React.Component {

    // props are "text, image, animate"

    render() {
        const {text, image, animate, children} = this.props;

        return (
            <ScrollAnimation animateIn={'fadeIn'}>
                <div className={"section full bg " + image}>
                    <div className="overlay"/>
                    <div className="features">
                        <ScrollAnimation animateIn={animate}>
                            {text ? (<span>{text}</span>) : null}
                            {children}
                        </ScrollAnimation>
                    </div>
                </div>
            </ScrollAnimation>
        );
    }
}
